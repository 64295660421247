import { LoadedNativeDossier, NativeVisualsDossier } from "../../../model/userNativeReportsModel";
import NativeDossier from "./NativeDossier";

export interface NativeDossierContainerProp {
  nativeVisualsDossiers: NativeVisualsDossier[];
  onDossierRefreshed: (loadedDossier: LoadedNativeDossier) => void;
}

function NativeDossierContainer(props: NativeDossierContainerProp) {
  return (
    <NativeDossier
      nativeVisualsDossiers={props.nativeVisualsDossiers}
      onDossierRefreshed={props.onDossierRefreshed}
    ></NativeDossier>
  );
}

export default NativeDossierContainer;
