import { useCallback, useEffect, useState } from "react";
import Logout from "@common-core/okta-logout";
import Loader from "../loader/Loader";
import { mstrLogout } from "../../../services/mstrRestService";
import { clearUserReports } from "../../../services/userSettingsService";
import { ErrorMessages, LoaderMessages } from "../../../utility/Messages";
import { clearPendoModel } from "../../../services/pendoService";
import {
  clearAccountingInstanceIds,
  clearUserMstrId,
  clearBridgeUserModel,
  clearUserNativeReports,
  clearUserAccount,
  clearNativeDossierGlobalFilterKeys,
  clearUserPreference,
} from "../../../utility/sessionStorageHelper";
import { useNavigate } from "react-router-dom";
import getError from "../../../utility/errorHelper";
import { LogType } from "../../../enum";
import { logService } from "../../../services/logService";

function AppLogout() {
  const [mstrLoggedOut, setMstrLoggedOut] = useState<"done" | "started">(
    "started"
  );
  const navigate = useNavigate();

  const navigateToError = useCallback(() => {
    navigate("/error", {
      state: {
        error: getError(ErrorMessages.mstrSessionCloseError),
      },
    });
  }, [navigate]);

  useEffect(() => {
    cleanup();

    if (mstrLoggedOut === "started") {
      mstrLogout()
        .then((response) => {
          if (response.ok) {
            setMstrLoggedOut("done");
          } else {
            logService.log({
              message: "Error occurred in closing MSTR session.",
              type: LogType.Error,
              method: "AppLogout",
              file: "AppLogout.tsx",
            });
            response.text().then((text) => {
              logService.log({
                message: text,
                type: LogType.Information,
                method: "AppLogout",
                file: "AppLogout.tsx",
              });
            });
            navigateToError();
          }
        })
        .catch((error) => {
          logService.log({
            message: `${error}`,
            type: LogType.Error,
            method: "AppLogout",
            file: "AppLogout.tsx",
          });
          navigateToError();
        });
    }

    function cleanup() {
      clearUserReports();
      clearUserAccount();
      clearAccountingInstanceIds();
      clearPendoModel();
      clearUserMstrId();
      clearBridgeUserModel();
      clearUserNativeReports();
      clearNativeDossierGlobalFilterKeys();
      clearUserPreference();
    }
  }, [mstrLoggedOut, navigateToError]);

  if (mstrLoggedOut === "done") return <Logout />;

  return <Loader message={LoaderMessages.mstrSessionLogout} />;
}

export default AppLogout;
