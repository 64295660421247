import { useCallback, useEffect, useRef, useState } from "react";
import FilterBar from "../filterBar/FilterBar";
import Select from "../select/Select";
import SlideOut from "../slideOut/SlideOut";
import {
  COMPANY_ATTRIBUTE_ID,
  ENTERPRISE_ATTRIBUTE_ID,
} from "../../../config/mstrConfig";
import "./NativeFilter.scss";
import {
  applyGlobalFilters,
  getLastAppliedGlobalFilters,
  setLastAppliedGlobalFilters,
  getNativeDossierGlobalFilterKeys,
  getAttributeElements,
} from "../../../services/nativeDossierService";
import { FilterCurrentSelection } from "../../../model/dossierDefinitionModel";
import {
  FilterModel,
  LoadedNativeDossier,
  SelectOption,
} from "../../../model/userNativeReportsModel";
import { FilterPreference } from "../../../model/userPreferenceModel";

type NativeFilterType = "enterprise" | "company" | "default";

interface TempSelectionFilterModel extends FilterModel {
  enterpriseUpdated: boolean;
  companyUpdated: boolean;
}

interface NativeFilterProps {
  loadedDossiers: LoadedNativeDossier[];
}

function NativeFilter(props: NativeFilterProps) {
  const [showFilter, setShowFilter] = useState(false);
  const [enterprises, setEnterprises] = useState<SelectOption[]>([]);
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<FilterModel>({
    enterprises: [],
    companies: [],
  });
  const [applyingFilter, setApplyingFilter] = useState(false);
  const [displayEnterpriseName, setDisplayEnterpriseName] =
    useState<string>(" ");
  const [displayCompanyName, setDisplayCompanyName] = useState<string>(" ");
  const [enterpriseLoaded, setEnterpriseLoaded] = useState(false);
  const [companyLoaded, setCompanyLoaded] = useState(false);

  const tempSelectionFilterModel = useRef<TempSelectionFilterModel>({
    enterprises: [],
    companies: [],
    enterpriseUpdated: false,
    companyUpdated: false,
  });

  const getFilterModelFromFilterPreference = useCallback(
    (filterPreference: FilterPreference): FilterModel => {
      const filterModel: FilterModel = { enterprises: [], companies: [] };
      filterPreference.enterprises.forEach((e) => {
        const label = enterprises.find((en) => en.value === e)?.label;
        if (label) filterModel.enterprises.push({ value: e, label: label });
      });
      filterPreference.companies.forEach((e) => {
        const label = companies.find((en) => en.value === e)?.label;
        if (label) filterModel.companies.push({ value: e, label: label });
      });
      return filterModel;
    },
    [enterprises, companies]
  );

  const setTempSelection = useCallback(
    (nativeFilterType: NativeFilterType, selectOptions: SelectOption[]) => {
      if (nativeFilterType === "enterprise") {
        tempSelectionFilterModel.current.enterprises = selectOptions;
        tempSelectionFilterModel.current.enterpriseUpdated = true;
      } else if (nativeFilterType === "company") {
        tempSelectionFilterModel.current.companies = selectOptions;
        tempSelectionFilterModel.current.companyUpdated = true;
      } else {
        tempSelectionFilterModel.current.enterpriseUpdated = false;
        tempSelectionFilterModel.current.enterprises = [];
        tempSelectionFilterModel.current.companyUpdated = false;
        tempSelectionFilterModel.current.companies = [];
      }
    },
    []
  );

  const applyFilter = useCallback(
    async (toBeAppliedFilters: TempSelectionFilterModel, save?: boolean) => {
      if (
        !toBeAppliedFilters.companyUpdated &&
        !toBeAppliedFilters.enterpriseUpdated
      )
        return;
      setApplyingFilter(true);
      const dossiersGlobalFilterKeys = getNativeDossierGlobalFilterKeys();
      const allEnterpriseSelected = toBeAppliedFilters.enterprises.length === 0;
      const currentEnterpriseSelection: FilterCurrentSelection | undefined =
        toBeAppliedFilters.enterpriseUpdated
          ? {
              allSelected: allEnterpriseSelected,
              elements: allEnterpriseSelected
                ? undefined
                : toBeAppliedFilters.enterprises.map((e) => ({
                    id: e.value,
                  })),
            }
          : undefined;
      const allCompanySelected = toBeAppliedFilters.companies.length === 0;
      const currentCompanySelection: FilterCurrentSelection | undefined =
        toBeAppliedFilters.companyUpdated
          ? {
              allSelected: allCompanySelected,
              elements: allCompanySelected
                ? undefined
                : toBeAppliedFilters.companies.map((e) => ({
                    id: e.value,
                  })),
            }
          : undefined;
      const promises: Promise<void>[] = [];
      props.loadedDossiers.forEach((loadedDossier) => {
        const dossierGlobalFilterKeys = dossiersGlobalFilterKeys.find(
          (d) => d.dossierId === loadedDossier.id
        );
        if (dossierGlobalFilterKeys)
          promises.push(
            applyGlobalFilters(
              loadedDossier,
              dossierGlobalFilterKeys,
              currentEnterpriseSelection,
              currentCompanySelection
            )
          );
      });
      await Promise.all(promises);
      const existingFilters = await getLastAppliedGlobalFilters();
      const filterPreference: FilterPreference = {
        enterprises: toBeAppliedFilters.enterpriseUpdated
          ? toBeAppliedFilters.enterprises.map((e) => e.value)
          : existingFilters.enterprises,
        companies: toBeAppliedFilters.companyUpdated
          ? toBeAppliedFilters.companies.map((c) => c.value)
          : existingFilters.companies,
      };
      const filterModel = getFilterModelFromFilterPreference(filterPreference);
      setSelected(filterModel);
      if (save) await setLastAppliedGlobalFilters(filterPreference);
      setApplyingFilter(false);
      setTempSelection("default", []);
    },
    [getFilterModelFromFilterPreference, props.loadedDossiers, setTempSelection]
  );

  const handleFilterToggle = useCallback(
    async (apply: boolean) => {
      setShowFilter((prev) => !prev);
      if (apply) {
        await applyFilter({ ...tempSelectionFilterModel.current }, true);
      } else {
        setTempSelection("default", []);
      }
    },
    [applyFilter, setTempSelection]
  );

  useEffect(() => {
    if (enterprises.length === 0) {
      setDisplayEnterpriseName(" ");
    } else if (enterprises.length === 1) {
      setDisplayEnterpriseName(enterprises[0].label);
    } else {
      setDisplayEnterpriseName(
        selected.enterprises.map((e) => e.label).join(" , ")
      );
    }
  }, [enterprises, selected.enterprises]);

  useEffect(() => {
    if (companies.length === 0) {
      setDisplayCompanyName(" ");
    } else if (companies.length === 1) {
      setDisplayCompanyName(companies[0].label);
    } else {
      setDisplayCompanyName(selected.companies.map((e) => e.label).join(" , "));
    }
  }, [companies, selected.companies]);

  useEffect(() => {
    getUserEnterprises();
    async function getUserEnterprises() {
      const elements = await getAttributeElements(ENTERPRISE_ATTRIBUTE_ID);
      if (elements) {
        setEnterprises(
          elements.map((e) => ({
            label: e.name!,
            value: `${e.id.substring(
              0,
              e.id.indexOf(";") + 1
            )}${ENTERPRISE_ATTRIBUTE_ID}`,
          }))
        );
      }
      setEnterpriseLoaded(true);
    }
  }, []);

  useEffect(() => {
    getUserCompanies();
    async function getUserCompanies() {
      const elements = await getAttributeElements(COMPANY_ATTRIBUTE_ID);
      if (elements) {
        setCompanies(
          elements.map((e) => ({
            label: e.name!.substring(0, e.name!.indexOf(":")),
            value: `${e.id.substring(
              0,
              e.id.indexOf(";") + 1
            )}${COMPANY_ATTRIBUTE_ID}`,
          }))
        );
      }
      setCompanyLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (enterpriseLoaded && companyLoaded) {
      initializeFilters();
    }

    async function initializeFilters() {
      const filterPreference = await getLastAppliedGlobalFilters();
      const filters = getFilterModelFromFilterPreference(filterPreference);
      setTempSelection("enterprise", filters.enterprises);
      setTempSelection("company", filters.companies);
      await applyFilter({ ...tempSelectionFilterModel.current });
    }
  }, [
    applyFilter,
    companyLoaded,
    enterpriseLoaded,
    getFilterModelFromFilterPreference,
    setTempSelection,
  ]);

  return (
    <>
      <FilterBar
        enterprise={displayEnterpriseName}
        company={displayCompanyName}
        click={() => handleFilterToggle(false)}
      ></FilterBar>
      <SlideOut
        show={showFilter}
        onClose={handleFilterToggle}
        disabled={applyingFilter}
      >
        <div className="componentsContainer" data-testid="componentsContainer">
          <div className="components" data-testid="enterprise">
            <Select
              label="Enterprise"
              options={enterprises}
              multiple
              values={selected.enterprises}
              readonly={applyingFilter}
              onChange={(selections) =>
                setTempSelection("enterprise", selections)
              }
            ></Select>
          </div>
          <div className="components" data-testid="company">
            <Select
              label="Company"
              options={companies}
              multiple
              values={selected.companies}
              readonly={applyingFilter}
              onChange={(selections) => setTempSelection("company", selections)}
            ></Select>
          </div>
        </div>
      </SlideOut>
    </>
  );
}

export default NativeFilter;
