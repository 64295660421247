import { useCallback, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "@interstate/components/Button";
import { ChevronDownIcon } from "@interstate/components/Icons/ChevronDownIcon";
import "./NavBarItem.scss";
import useCustomSearchParams from "../../../utility/useCustomSearchParam";

interface NavSubItemProps {
  id: string;
  text: string;
}

interface NavBarItemProps extends NavSubItemProps {
  subMenus?: NavSubItemProps[];
  selected?: boolean;
}

function NavBarItem(props: NavBarItemProps) {
  const [containsSubMenu, setContainsSubMenu] = useState<boolean>(false);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const mstrInvoked = (useCustomSearchParams("mstr_invoked") ??
    false) as boolean;

  const getMSTRParam = useCallback(
    (mstrInvoked: boolean) => (mstrInvoked ? "mstr_invoked=true" : ""),
    []
  );
  const [mstrInvokedParam, setMstrInvokedParam] = useState(
    getMSTRParam(mstrInvoked)
  );

  useEffect(() => {
    setMstrInvokedParam(getMSTRParam(mstrInvoked));
  }, [getMSTRParam, mstrInvoked]);

  useEffect(() => {
    if (props.subMenus && props.subMenus.length > 0) {
      setContainsSubMenu(true);
    } else setContainsSubMenu(false);
  }, [props.subMenus]);

  const handleMenuClick = () => {
    const route =
      props.text === "Home"
        ? `home?${mstrInvokedParam}`
        : `report?id=${props.id}&${mstrInvokedParam}`;
    navigate(`/${route}`);
  };

  const handleHover = (hover: boolean) => {
    setSubMenuOpen(hover);
  };

  const buttonId = subMenuOpen
    ? "navBarItemHovered"
    : props.selected
    ? "navBarItemSelected"
    : "navBarItem";

  const subMenuClass = subMenuOpen
    ? "dropdown-content dropDownContentShow"
    : "dropdown-content";

  if (!containsSubMenu) {
    return (
      <div>
        <Button
          id={props.selected ? "navBarItemSelected" : "navBarItem"}
          onClick={handleMenuClick}
        >
          <span id="navBarItemLabel">{props.text}</span>
        </Button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <Button
        id={buttonId}
        endIcon={<ChevronDownIcon />}
        onMouseOver={() => handleHover(true)}
        onMouseOut={() => handleHover(false)}
      >
        <span id="navBarItemLabel">{props.text}</span>
      </Button>
      <div
        data-testid="sub-menu-container"
        id={props.id}
        className={subMenuClass}
        onMouseOver={() => handleHover(true)}
        onMouseOut={() => handleHover(false)}
      >
        {props.subMenus!.map((submenu) => (
          <NavLink
            key={submenu.id}
            id={submenu.id}
            to={`/report?id=${submenu.id}&${mstrInvokedParam}`}
            className="subMenuLink"
          >
            <span className="subMenuLabel">{submenu.text}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default NavBarItem;
