import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { buildOktaAuth } from "@bridge/okta-auth";
import { LoginCallback, Security } from "@okta/okta-react";
import { currentEnvironment } from "./environments/environments";
import { SecureRoute } from "./components/security/SecureRoute";
import "./App.scss";
import Loader from "./components/controls/loader/Loader";
import ErrorContainer from "./components/containers/errorContainer/ErrorContainer";
import AppLogout from "./components/controls/appLogout/AppLogout";
import LandingPage from "./components/pages/LandingPage";
import { ErrorMessages, LoaderMessages } from "./utility/Messages";
import { InitUserSettings } from "./services/userSettingsService";
import getError from "./utility/errorHelper";

function App() {
  const navigate = useNavigate();
  const [oktaAuth] = useState(() =>
    buildOktaAuth(currentEnvironment().authConfig)
  );
  const location = useLocation();

  useEffect(() => InitUserSettings(), []);

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div data-testid="app-component" className="App">
        <Routes>
          <Route
            path="/loginCallback"
            element={
              <LoginCallback
                loadingElement={<Loader message={LoaderMessages.signIn} />}
                errorComponent={({ error }: { error: Error }) => {
                  return (
                    <Navigate
                      to={"/error"}
                      state={{ error: error }}
                      replace={true}
                    />
                  );
                }}
              />
            }
          />
          <Route path="/error" element={<ErrorContainer />} />
          <Route path="/logout" element={<AppLogout />} />
          <Route path="/" element={<SecureRoute />}>
            <Route
              index
              path="/"
              element={<Navigate to={`/home${location.search}`} />}
            ></Route>
            <Route path="/report/*" element={<LandingPage />} />
            <Route path="/home/*" element={<LandingPage />} />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to="/error"
                replace={true}
                state={{
                  error: getError(ErrorMessages.incorrectResource),
                }}
              />
            }
          />
        </Routes>
      </div>
    </Security>
  );
}

export default App;
