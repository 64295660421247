import { ComboBox, ComboBoxEventValue } from "@interstate/components/ComboBox";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { useCallback } from "react";
import { SelectOption } from "../../../model/userNativeReportsModel";

interface SelectProps {
  label: string;
  options: SelectOption[];
  multiple?: boolean;
  values?: SelectOption[];
  onChange?: (selected: SelectOption[]) => void;
  readonly?: boolean;
}

function Select(props: SelectProps) {
  const handleSelectionChange = useCallback(
    (e: InterstateOnChangeEvent<ComboBoxEventValue>) => {
      if (props.onChange) props.onChange(e?.target?.value ?? []);
    },
    [props]
  );

  return (
    <ComboBox
      id={props.label}
      label={props.label}
      multiple={props.multiple}
      options={props.options}
      value={props.values}
      onChange={handleSelectionChange}
      disabled={props.readonly}
    ></ComboBox>
  );
}

export default Select;
