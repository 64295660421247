import { useCallback, useEffect, useState } from "react";
import {
  createMstrEnvironment,
  loadDossier,
  refreshDossier,
  unloadDossier,
  unloadDossiers,
} from "../../../services/nativeDossierService";
import { LogType } from "../../../enum";
import { logService } from "../../../services/logService";
import {
  LoadedNativeDossier,
  NativeVisualsDossier,
} from "../../../model/userNativeReportsModel";

interface NativeDossierProps {
  nativeVisualsDossiers: NativeVisualsDossier[];
  onDossierRefreshed: (loadedDossier: LoadedNativeDossier) => void;
}

function NativeDossier(props: NativeDossierProps) {
  const { nativeVisualsDossiers, onDossierRefreshed } = props;
  const [mstrEnvironment, setMstrEnvironment] = useState<any>();

  const loadVisualizationsFromDossier = useCallback(
    async (
      mstrEnvironment: any,
      commonMstrVisualsDossier: NativeVisualsDossier,
      dossiers: any[],
      signal: AbortSignal,
      attemptLeft: number
    ) => {
      let mstrDossier: any;
      try {
        //Load Dossier
        mstrDossier = await loadDossier(
          mstrEnvironment,
          commonMstrVisualsDossier.dossierId
        );
        if (!mstrDossier) {
          if (attemptLeft === 0) {
            throw Error(
              `unable to load dossier with dossierId: ${commonMstrVisualsDossier.dossierId}`
            );
          } else {
            setTimeout(
              () =>
                loadVisualizationsFromDossier(
                  mstrEnvironment,
                  commonMstrVisualsDossier,
                  dossiers,
                  signal,
                  --attemptLeft
                ),
              5000
            );
          }
          return;
        }
        dossiers.push(mstrDossier);

        //Refresh Dossier to load visualization
        const isRefreshed = await refreshDossier(
          mstrDossier,
          commonMstrVisualsDossier.visuals.map((visual: string) => ({
            key: visual,
            container: document.getElementById(
              `${commonMstrVisualsDossier.dossierId}-${visual}`
            ),
          })),
          signal
        );
        if (!isRefreshed) {
          throw Error(
            `unable to refresh dossier with dossierId: ${commonMstrVisualsDossier.dossierId}`
          );
        }
        onDossierRefreshed({
          id: commonMstrVisualsDossier.dossierId,
          dossier: mstrDossier,
        });
      } catch (e) {
        logService.log({
          message: `${e}`,
          type: LogType.Error,
          method: "loadVisualizationsFromDossier",
          file: "NativeDossier.tsx",
        });
        if (mstrDossier) unloadDossier(mstrEnvironment, mstrDossier);
      }
    },
    [onDossierRefreshed]
  );

  const loadVisualizationsFromDossiers = useCallback(
    (
      mstrEnvironment: any,
      commonMstrVisualsDossiers: NativeVisualsDossier[],
      dossiers: any[],
      signal: AbortSignal
    ) => {
      commonMstrVisualsDossiers.map(
        (commonMstrVisualsDossier: NativeVisualsDossier) =>
          loadVisualizationsFromDossier(
            mstrEnvironment,
            commonMstrVisualsDossier,
            dossiers,
            signal,
            5
          )
      );
    },
    [loadVisualizationsFromDossier]
  );

  useEffect(() => {
    setupMstrEnvironment();
    async function setupMstrEnvironment() {
      const mstrEnvironment = await createMstrEnvironment();
      setMstrEnvironment(mstrEnvironment);
    }
  }, []);

  useEffect(() => {
    if (!mstrEnvironment) return;
    const abortController = new AbortController();
    const dossiers: any[] = [];
    loadVisualizationsFromDossiers(
      mstrEnvironment,
      nativeVisualsDossiers,
      dossiers,
      abortController.signal
    );
    return function cleanup() {
      try {
        abortController.abort("clean up on unmount");
        unloadDossiers(mstrEnvironment, dossiers);
      } catch (e) {
        logService.log({
          message: `${e}`,
          type: LogType.Error,
          method: "cleanup",
          file: "NativeDossier.tsx",
        });
      }
    };
  }, [nativeVisualsDossiers, loadVisualizationsFromDossiers, mstrEnvironment]);

  return <></>;
}

export default NativeDossier;
