import "./Loader.scss";
import Modal from "../modal/Modal";
import Spinner from "../spinner/Spinner";

interface LoaderProps {
  message?: string;
  size?: number;
}
function Loader(props: LoaderProps) {
  return (
    <Modal open>
      <div className="tokenLoader">
        <Spinner size={props.size ?? 60} />
        <div className="loaderMsg">{props.message}</div>
      </div>
    </Modal>
  );
}
export default Loader;
