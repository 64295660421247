import { FunnelIcon } from "@interstate/components/Icons/FunnelIcon";
import "./FilerBar.scss";

interface FilterBarProps {
  containerStyle?: React.CSSProperties;
  funnelStyle?: React.CSSProperties;
  click?: () => void;
  enterprise?: string;
  company?: string;
}

function FilterBar(props: FilterBarProps) {
  return (
    <div className={"filterContainer"} style={props.containerStyle}>
      <div className="filterTitle">
        <div className="filterValue">
          <span className="filterHeader">Enterprise</span>
          <span className="">(</span>
          <span className="filterText">{props.enterprise ?? "ALL"} </span>
          <span className="">)</span>
        </div>
        <div>
          <span className="filterSeparate">|</span>
        </div>
        <div className="filterValue">
          <span className="filterHeader">Company</span>
          <span className="">(</span>
          <span className="filterText">{props.company ?? "ALL"}</span>
          <span className="">)</span>
        </div>
      </div>
      <div className="filterFunnel">
        <FunnelIcon
          fontSize="18px"
          style={props.funnelStyle}
          onClick={props.click}
        ></FunnelIcon>
      </div>
    </div>
  );
}

export default FilterBar;
