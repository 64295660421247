import { MSTR_PROJECT_ID } from "../config/mstrConfig";
import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import {
  DossierGlobalFilterKey,
  FilterCurrentSelection,
} from "../model/dossierDefinitionModel";
import { LoadedNativeDossier } from "../model/userNativeReportsModel";
import { FilterPreference, UserPreference } from "../model/userPreferenceModel";
import {
  getBridgeUserModel,
  getNativeDossierGlobalFilterKeys as getNativeDossierGlobalFilterKeysFromSession,
  getUserPreference,
  setUserPreference,
} from "../utility/sessionStorageHelper";
import { UserSettingsApiService } from "./apiService";
import { logService } from "./logService";
import {
  getAttributeElements as getAttributeElementsFromMstr,
  getToken,
} from "./mstrRestService";

export async function createMstrEnvironment() {
  const environment = currentEnvironment();
  try {
    const mstr = (window as any).microstrategy;
    mstr.nativeEmbedding.featureFlags.multipleDossiers = true;
    return await mstr.embeddingComponent.environments.create({
      serverUrl: environment.customConfiguration.mstrUrl,
      getAuthToken: async () => {
        try {
          const response = await getToken();
          return response;
        } catch (e) {
          logService.log({
            message: `${e}`,
            type: LogType.Error,
            method: "createMstrEnvironment",
            file: "NativeDossierService.ts",
          });
        }
      },
    });
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "createMstrEnvironment",
      file: "NativeDossierService.ts",
    });
  }
}

export function unloadDossiers(mstrEnvironment: any, dossiers: any[]) {
  dossiers.forEach((dossier) => {
    try {
      unloadDossier(mstrEnvironment, dossier);
    } catch (e) {
      logService.log({
        message: `${e}`,
        type: LogType.Error,
        method: "unloadDossiers",
        file: "NativeDossierService.ts",
      });
    }
  });
}

export function unloadDossier(mstrEnvironment: any, dossier: any) {
  mstrEnvironment.unloadDossier(dossier).catch((e: any) =>
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "unloadDossier",
      file: "NativeDossierService.ts",
    })
  );
}

export async function loadDossier(mstrEnvironment: any, dossierId: string) {
  try {
    const dossier = await mstrEnvironment.loadDossier({
      projectId: MSTR_PROJECT_ID,
      objectId: dossierId,
    });
    return dossier;
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "loadDossier",
      file: "NativeDossierService.ts",
    });
  }
}

export async function refreshDossier(
  mstrDossier: any,
  vizAndContainers: { key: string; container: HTMLElement | null }[],
  signal: AbortSignal
): Promise<boolean> {
  try {
    await mstrDossier.refresh(vizAndContainers, {
      signal: signal,
    });
    return true;
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "refreshDossier",
      file: "NativeDossierService.ts",
    });
    return false;
  }
}

export async function applyFilter(
  mstrDossier: any,
  key: string,
  currentSelection: FilterCurrentSelection
) {
  try {
    await mstrDossier.applyFilter({
      key: key,
      currentSelection: currentSelection,
    });
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "applyFilter",
      file: "NativeDossierService.ts",
    });
  }
}

export async function applyGlobalFilters(
  loadedDossier: LoadedNativeDossier,
  dossierGlobalFilterKeys: DossierGlobalFilterKey,
  enterprises?: FilterCurrentSelection,
  companies?: FilterCurrentSelection
) {
  if (enterprises) {
    for (let i = 0; i < dossierGlobalFilterKeys.enterpriseKeys.length; i++) {
      await applyFilter(
        loadedDossier.dossier,
        dossierGlobalFilterKeys.enterpriseKeys[i],
        enterprises
      );
    }
  }

  if (companies) {
    for (let i = 0; i < dossierGlobalFilterKeys.companyKeys.length; i++) {
      await applyFilter(
        loadedDossier.dossier,
        dossierGlobalFilterKeys.companyKeys[i],
        companies
      );
    }
  }
}

export async function getLastAppliedGlobalFilters(): Promise<FilterPreference> {
  const model = getBridgeUserModel();
  try {
    let userPreference = getUserPreference();
    if (userPreference) return userPreference;
    const environment = currentEnvironment();
    if (!model?.accessToken) {
      throw Error("access token now present");
    }
    const response = await UserSettingsApiService.get(
      `${environment.customConfiguration.userSettingUrl}/user/preference`,
      model.accessToken
    );
    if (response && response.ok) {
      const userPreference = (await response.json()) as UserPreference;
      setUserPreference(userPreference);
      return userPreference;
    }
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getLastAppliedGlobalFilters",
      file: "NativeDossierService.ts",
    });
  }
  return { enterprises: [], companies: [] };
}

export async function setLastAppliedGlobalFilters(
  filterPreference: FilterPreference
) {
  try {
    const model = getBridgeUserModel();
    if (!model?.accessToken) {
      throw Error("access token now present");
    }

    const userPreference: UserPreference = {
      ...filterPreference,
      sub: model.sub,
    };
    setUserPreference(userPreference);
    const environment = currentEnvironment();
    const response = await UserSettingsApiService.post(
      `${environment.customConfiguration.userSettingUrl}/user/preference`,
      userPreference,
      model.accessToken
    );
    if (!response?.ok) {
      throw Error(`Unable to save user preference for user ${model.sub}`);
    }
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "setLastAppliedGlobalFilters",
      file: "NativeDossierService.ts",
    });
  }
}

export function getNativeDossierGlobalFilterKeys() {
  return getNativeDossierGlobalFilterKeysFromSession();
}

export async function getAttributeElements(attributeId: string) {
  return await getAttributeElementsFromMstr(attributeId);
}
