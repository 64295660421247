import { useCallback, useEffect, useMemo, useState } from "react";
import NativeDossierContainer from "../../mstr/nativeDossier/NativeDossierContainer";
import { useNavigate } from "react-router-dom";
import "./NativeDashboardContainer.scss";
import {
  LoadedNativeDossier,
  NativeVisualsDossier,
  NativeVisualsDossierGroup,
} from "../../../model/userNativeReportsModel";
import Spinner from "../../controls/spinner/Spinner";
import NativeFilter from "../../controls/nativeFilter/NativeFilter";

interface NativeDashboardContainerProps {
  nativeVisualsDossierGroups: NativeVisualsDossierGroup[];
}

function NativeDashboardContainer(props: NativeDashboardContainerProps) {
  const { nativeVisualsDossierGroups } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadedDossiers, setLoaderDossiers] = useState<LoadedNativeDossier[]>(
    []
  );
  const navigate = useNavigate();
  const [allDossierLoaded, setAllDossierLoaded] = useState(false);

  const handleGraphicSelect = useCallback(
    (dossierId: string, pageKey: string) => {
      navigate(`/report?id=${dossierId}&page=${pageKey}&mstr_invoked=true`);
    },
    [navigate]
  );

  const handleDossierRefreshed = useCallback(
    (loadedDossier: LoadedNativeDossier) => {
      setLoaderDossiers((prev) => {
        if (!prev.some((p) => p.id === loadedDossier.id))
          prev.push(loadedDossier);
        return [...prev];
      });
    },
    []
  );

  const nativeVisualsDossiers = useMemo(() => {
    const visualsDossiers: NativeVisualsDossier[] = [];
    nativeVisualsDossierGroups.forEach((groupVisualDossiers) =>
      groupVisualDossiers.nativePagesDossiers.forEach((pageVisualDossier) => {
        const visualsDossier: NativeVisualsDossier = {
          dossierId: pageVisualDossier.dossierId,
          visuals: [],
        };
        pageVisualDossier.nativeVisualsPages.forEach((p) =>
          visualsDossier.visuals.push(...p.visuals)
        );
        visualsDossiers.push(visualsDossier);
      })
    );
    return visualsDossiers;
  }, [nativeVisualsDossierGroups]);

  const nativeContainers = useMemo(() => {
    return nativeVisualsDossierGroups.map((nativeVisualsDossierGroup) => {
      return (
        <div
          className={"nativeGroupContainer"}
          key={nativeVisualsDossierGroup.group}
        >
          <div className={"nativeGroup"}>{nativeVisualsDossierGroup.group}</div>
          <div
            className={"nativeVisualsContainer"}
            key={`${nativeVisualsDossierGroup.group}`}
          >
            {nativeVisualsDossierGroup.nativePagesDossiers.map(
              (nativePagesDossier) => {
                return nativePagesDossier.nativeVisualsPages.map(
                  (nativeVisualPage) => {
                    return nativeVisualPage.visuals.map((visual) => {
                      return (
                        <div
                          id={`${nativePagesDossier.dossierId}-${visual}`}
                          data-testid={`${nativePagesDossier.dossierId}-${visual}`}
                          className={`nativeVisual ${
                            loadedDossiers.some(
                              (ld) => ld.id === nativePagesDossier.dossierId
                            )
                              ? ""
                              : "border"
                          }`}
                          key={`visual-${nativePagesDossier.dossierId}-${visual}`}
                          onClick={() =>
                            handleGraphicSelect(
                              nativePagesDossier.dossierId,
                              nativeVisualPage.pageKey
                            )
                          }
                          //@ts-ignore
                          sandbox="allow-same-origin allow-scripts"
                        ></div>
                      );
                    });
                  }
                );
              }
            )}
          </div>
        </div>
      );
    });
  }, [handleGraphicSelect, loadedDossiers, nativeVisualsDossierGroups]);

  useEffect(() => setIsLoaded(true), []);
  useEffect(() => {
    if (loadedDossiers.length === nativeVisualsDossiers.length)
      setAllDossierLoaded(true);
  }, [loadedDossiers.length, nativeVisualsDossiers.length]);

  return (
    <>
      {!allDossierLoaded && (
        <div className="spinner" data-testid="spinner">
          <Spinner size={75}></Spinner>
        </div>
      )}
      {allDossierLoaded && loadedDossiers.length > 0 && (
        <NativeFilter loadedDossiers={loadedDossiers}></NativeFilter>
      )}
      {nativeContainers}
      {isLoaded && (
        <NativeDossierContainer
          nativeVisualsDossiers={nativeVisualsDossiers}
          onDossierRefreshed={handleDossierRefreshed}
        ></NativeDossierContainer>
      )}
    </>
  );
}

export default NativeDashboardContainer;
