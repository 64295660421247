import { SlideOut as SLO } from "@interstate/components/SlideOut";

interface SlideOutProps {
  children: any;
  show: boolean;
  onClose: (apply: boolean) => void;
  header?: string;
  disabled?: boolean;
}

function SlideOut(props: SlideOutProps) {
  return (
    <SLO
      panelWidth={"296px"}
      show={props.show}
      header={props.header}
      onHide={() => props.onClose(false)}
      position="right"
      topOffset={"94px"}
      footer={{
        primary: {
          label: "Apply",
          action: () => props.onClose(true),
          disabled: props.disabled,
        },
      }}
    >
      {props.children}
    </SLO>
  );
}
export default SlideOut;
