import { Spinner as Sr } from "@interstate/components/Spinner";

interface SpinnerProps {
  size?: number;
}
function Spinner(props: SpinnerProps) {
  return <Sr size={props.size} />;
}

export default Spinner;
